import React from "react"
import styles from "./ServiceInList.module.scss"
import BackgroundImage from "gatsby-background-image"
import Button from "../Button"
import PropTypes from 'prop-types';
import Fade from "react-reveal/Fade"
import { Link } from "gatsby"

const Service = ({ service }) => {
  return (
    <article className={`${styles.service} container`}>
      <div className={styles.textBox}>
        <Fade bottom duration={700}>
          <Link to={`/sluzby/${service.node.slug}`}>
            <h3>
              {service.node.serviceName ? `${service.node.serviceName} ` : ""}
              {service.node.serviceNameBold}
            </h3>
          </Link>
        </Fade>
        <Fade bottom duration={700}>
          <p className={styles.text}>
            {service.node.serviceDescInList.serviceDescInList}
          </p>
        </Fade>
        <Fade bottom duration={700}>
          <Button
            link={`/sluzby/${service.node.slug}`}
            text={"Zobrazit více"}
          />
        </Fade>
      </div>
      <div className={styles.imgBox}>
        <BackgroundImage
          fluid={service.node.mainImg.fluid}
          className={styles.img}
        />
      </div>
    </article>
  )
}

export default Service

Service.propTypes = {
  service: PropTypes.object
}
