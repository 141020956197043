import React from "react"
import styles from "./Videos.module.scss"
import Fade from "react-reveal/Fade"
import PropTypes from 'prop-types';

function Videos({ data, isLaser }) {
  return (
    <>
      <Fade bottom duration={700}>
        <h3 style={{ marginTop: isLaser && "7rem" }}>
          {!isLaser ? "Videa projektu" : "Ukázka pálení"}
        </h3>
      </Fade>
      <div
        className={styles.videos}
        style={{ marginBottom: isLaser ? "2rem" : "4rem" }}
      >
        {data.map((video, i) => {
          return (
            <Fade bottom duration={700}>
              <video
                preload="metadata"
                key={i}
                className={styles.video}
                controls
              >
                <source src={video.file.url} type="video/mp4" />
              </video>
            </Fade>
          )
        })}
      </div>
    </>
  )
}

export default Videos

Videos.propTypes = {
  data: PropTypes.array,
  isLaser: PropTypes.bool,
}
