import React from "react"
import styles from "./WorkSection.module.scss"
import Button from "../Button"
import Fade from "react-reveal/Fade"
import { Link, useStaticQuery, graphql } from "gatsby"
import PropTypes from 'prop-types';
import Img from "gatsby-image"

const getProjects = graphql`
  query {
    allContentfulVyrobky(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          projectName
          mainImg {
            description
            title
            fluid(maxWidth: 900, quality: 50) {
              ...GatsbyContentfulFluid
            }
          }
          slug
        }
      }
    }
  }
`

const WorkSection = ({ page, homepage }) => {
  const projects = useStaticQuery(getProjects).allContentfulVyrobky.edges
  const shuffled = [...projects].sort(() => 0.5 - Math.random())
  const random = shuffled.slice(0, 4)

  return (
    <section
      className={`${styles.section} ${page ? styles.sectionPage : ""} ${
        homepage ? styles.sectionHomepage : ""
      }`}
    >
      <div className="container">
        <Fade bottom duration={700}>
          <h2>
            Ukázky <span className="heading-bold">naší práce</span>
          </h2>
        </Fade>
        <Fade bottom duration={700}>
          <p>
            V naší dílně dokážeme vyrobit téměř cokoliv. Od bran, plotů,
            zábradlí, schodišť až po krby a grily. Máme za sebou stovky projektů
            a díky tomu jsme schopni nabídnout bohaté zkušenosti, které
            promítneme do maximální kvality výrobku.
          </p>
        </Fade>
        <div className={styles.worksection}>
          {random.map((rand,i) => {
            return (
              <Fade key={i} bottom duration={700}>
                <div className={`${styles.project}`}>
                  <Link to={`/reference/${rand.node.slug}`}>
                    <Img
                      fluid={rand.node.mainImg.fluid}
                      className={styles.img}
                    />
                    <div className={styles.title}>{rand.node.projectName}</div>
                  </Link>
                </div>
              </Fade>
            )
          })}
        </div>
        <Fade bottom duration={700}>
          <Button link="/reference" text="Zobrazit více" to="/prace" />
        </Fade>
      </div>
    </section>
  )
}

export default WorkSection

WorkSection.propTypes = {
  page: PropTypes.bool,
  homepage: PropTypes.bool
}