import React from "react"
import Layout from "../components/layout/Layout"
import { graphql } from "gatsby"
import SecondaryHeader from "../components/sections/SecondaryHeader"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import styles from "./servicePage.module.scss"
import SEO from "../components/SEO"
import WorkSection from "../components/sections/WorkSection"
import Cta from "../components/Cta"
import ServiceInList from "../components/services/ServiceInList"
import LaserContent from "../components/LaserContent"
import PropTypes from 'prop-types';
import Button from "../components/Button"
import Fade from "react-reveal/Fade"
import { BLOCKS } from "@contentful/rich-text-types"

export const query = graphql`
  query($slug: String!) {
    contentfulSluzba(slug: { eq: $slug }) {
      serviceName
      serviceNameBold
      serviceDescDetail {
        json
      }
      list {
        json
      }
      seoTitle
      seoDesc
      slug
      mainImg {
        fluid(maxWidth: 1600, quality: 60) {
          ...GatsbyContentfulFluid
        }
      }
    }
    allContentfulSluzba(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          createdAt(formatString: "LLL")
          serviceName
          serviceNameBold
          id
          serviceDesc {
            serviceDesc
          }
          mainImg {
            fluid(maxWidth: 1000, quality: 70) {
              ...GatsbyContentfulFluid
            }
          }
          serviceDescInList {
            serviceDescInList
          }
          slug
        }
      }
    }
  }
`

const serviceDetail = ({ data, pageContext }) => {
  const { contentfulSluzba, allContentfulSluzba } = data
  const {
    slug,
    serviceName,
    serviceNameBold,
    mainImg,
    serviceDescDetail,
    seoTitle,
    seoDesc,
    list,
  } = contentfulSluzba

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Fade bottom duration={700}>
          <p>{children}</p>
        </Fade>
      ),
    },
  }

  const optionsList = {
    renderNode: {
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <Fade bottom duration={700}>
          <li>{children}</li>
        </Fade>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Fade bottom duration={700}>
          <h3>{children}</h3>
        </Fade>
      ),
    },
  }

  let btn;

  if (slug === "restaurovani") {
    btn = (
      <Button
        link="/reference?kategorie=restaurovaní"
        text="Zobrazit reference"
      />
    )
  } else if (slug === "automaticke-pohony") {
    btn = (
      <Button
        link="/reference?kategorie=automatické pohony Tousek"
        text="Zobrazit reference"
      />
    )
  } else if (slug === "kovarstvi") {
    btn = (
      <Button 
        link="/reference" 
        text="Zobrazit naše výrobky"
      />
    )
  } else if (slug === "zamecnictvi") {
    btn = (
      <Button 
        link="/reference" 
        text="Zobrazit naše výrobky"
      />
    )
  }

  let fade = (
    <Fade bottom duration={700}>
      {btn}
    </Fade>
  )

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDesc} article />
      <SecondaryHeader
        pageContext={pageContext}
        disable={[`/sluzby/${slug}`]}
        crumbLabel={`${serviceName ? serviceName : ""} ${serviceNameBold}`}
        heading={serviceName ? serviceName : ""}
        bold={serviceNameBold}
        image={mainImg.fluid}
      />
      {slug !== "laserove-paleni" ? (
        <section className={`${styles.section} page-section`}>
          <div className="container">
            <article className={styles.content}>
              {documentToReactComponents(serviceDescDetail.json, options)}
            </article>
            {list && (
              <div
                className={
                  slug !== "restaurovani" ? styles.list : styles.listSec
                }
              >
                {documentToReactComponents(list.json, optionsList)}
                {fade}
              </div>
            )}
          </div>
        </section>
      ) : (
        <LaserContent />
      )}
      <section style={{ paddingBottom: "0rem", paddingTop: "10rem" }}>
        <div className="container">
          <Fade bottom duration={700}>
            <h2 className={styles.title}>
              Dále <span className="heading-bold">nabízíme</span>
            </h2>
          </Fade>
          {allContentfulSluzba.edges.map((service, index) => {
            if (service.node.slug === slug) {
              return false
            }
            return (
              <ServiceInList
                service={service}
                index={index}
                key={service.node.id}
              />
            )
          })}
        </div>
      </section>
      <WorkSection />
      <Cta small />
    </Layout>
  )
}

export default serviceDetail
